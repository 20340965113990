import {hasMarkdownContent} from "../../functions/hasMarkdownContent";
import {MarkdownRenderer} from "../MarkdownRenderer";
import React from "react";

import * as styles from './BlockLead.module.scss';
import classNames from "classnames";

export function BlockLead({content, className, ...props}) {
    if (hasMarkdownContent(content)) {
        return <MarkdownRenderer className={classNames(styles.root, className)} content={content}
                                 reduceHeaderVariantBy={2}
                                 reduceHeaderLevelBy={2}
                                 bodyVariant="lead" {...props}/>
    }
    return null;
}
