import classNames from 'classnames';
import * as styles from './Metric.module.scss';
import React from 'react';

export function Metric({title, className, alignedBottom, size="normal", children, appearance, ...otherProps}) {
    return <div className={classNames(className, styles.root, alignedBottom && styles.alignedBottom)}
                data-appearance={appearance}
                data-size={size}
                {...otherProps}>
        <span className={styles.title} data-ref="metric-title">{title}</span>
        <div className={styles.content} data-ref="metric-value">
            {children}
        </div>
    </div>
}
