import React from 'react';
import * as styles from './Testimonial.module.scss';
import classNames from 'classnames';
import {Typography} from "../Typography";
import {SVGSupportImg} from "../SVGSupportImg";
import {GatsbyImage} from "gatsby-plugin-image";
import {componentRef} from "../../functions/componentRef";

const TAB = [1, 2, 3, 4, 5];

export function Testimonial({
                                score,
                                logo,
                                image,
                                intro,
                                description,
                                person,
                                appearance = 'default',
                                className,
                                ...otherProps
                            }) {
    return (
        <article
            className={classNames(className, styles.root, styles[`appearance_${appearance}`])} {...componentRef('testimonials')}>
            {image ? <GatsbyImage
                image={image.gatsbyImageData}
                alt="Testimonial background"
                className={styles.image}
                style={{position: 'absolute'}}
            /> : undefined}
            {logo && <SVGSupportImg className={styles.logo}
                                    {...logo}
                                    alt="Testimonial logo"
            />
            }
            <div className={styles.stars}>
                {TAB.map((num, index) => {
                    const isActive = num <= score;
                    return <span className={classNames(styles.star, isActive && styles.active)} key={index}/>;
                })}
            </div>
            <h1 className={styles.intro}
                {...componentRef.subcomponent('testimonial-title')}>
                {intro}
            </h1>
            <Typography className={styles.description}>
                {description}
            </Typography>

            <div className={styles.person} {...componentRef.subcomponent('testimonial-article')}>
                <span className={styles.name}>
                    {person.name}
                </span>
                <span className={styles.position}>{person.position}{person.country ? ', ' : ''}</span>
                <span className={styles.country}>{person.country}</span>
            </div>
        </article>
    );
}
