// extracted by mini-css-extract-plugin
export var caseStudy = "SectionCaseStudiesBig-module--caseStudy--6eae7";
export var caseStudyIntro = "SectionCaseStudiesBig-module--caseStudyIntro--4e93a";
export var caseStudyLink = "SectionCaseStudiesBig-module--caseStudyLink--ed0c1";
export var caseStudyLinkWrapper = "SectionCaseStudiesBig-module--caseStudyLinkWrapper--9ed21";
export var caseStudyLogo = "SectionCaseStudiesBig-module--caseStudyLogo--0aa3f";
export var caseStudyLogoWrapper = "SectionCaseStudiesBig-module--caseStudyLogoWrapper--c6e01";
export var caseStudyMetrics = "SectionCaseStudiesBig-module--caseStudyMetrics--19e22";
export var caseStudyTitle = "SectionCaseStudiesBig-module--caseStudyTitle--c496f";
export var description = "SectionCaseStudiesBig-module--description--0076c";
export var header = "SectionCaseStudiesBig-module--header--03473";
export var mainWrapper = "SectionCaseStudiesBig-module--mainWrapper--5b7ad";
export var pagination = "SectionCaseStudiesBig-module--pagination--e811e";
export var root = "SectionCaseStudiesBig-module--root--d405a";
export var title = "SectionCaseStudiesBig-module--title--d6f75";
export var wrapper = "SectionCaseStudiesBig-module--wrapper--d8c04";