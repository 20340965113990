import React from 'react';
import classNames from 'classnames';

const styles = require('./ArrowPagination.module.scss');

export function ArrowPagination({
                                    hasPrevious = false,
                                    hasNext = false,
                                    className,
                                    onNextPage,
                                    onPreviousPage,
                                    ...otherProps
                                }) {
    return <div
        className={classNames(className, styles.root)}
        {...otherProps}
    >
        <button className={styles.previousButton}
                disabled={!hasPrevious}
                onClick={() => {
                    if (hasPrevious) {
                        onPreviousPage && onPreviousPage();
                    }
                }}
        >
            Previous
        </button>
        <button
            className={styles.nextButton}
            disabled={!hasNext}
            onClick={() => {
                if (hasNext) {
                    onNextPage && onNextPage();
                }
            }}
        >
            Next
        </button>
    </div>
}
