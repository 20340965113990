import {useCallback, useEffect, useState} from "react";


function getElementsForRef(ref) {
    return ref.current ? Array.from(ref.current.childNodes) : []
}

function getBaseCenterPosition(ref) {
    return ref.current.clientWidth / 2;
}

function getCenterPositionForRef(ref) {
    return getBaseCenterPosition(ref) + ref.current.scrollLeft;
}

function getCenterPositionForElement(element) {
    return element.offsetLeft - element.parentNode.offsetLeft + (element.clientWidth / 2)
}

export function useScrollSnap(ref) {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let element;
        const scroll = () => {
            const centerPosition = getCenterPositionForRef(ref);
            const distancesToCenter = getElementsForRef(ref).map((x, i) => {
                return {
                    index: i,
                    distance: Math.abs(
                        getCenterPositionForElement(x)
                        - centerPosition
                    )
                };
            })

            const closestIndex = distancesToCenter.sort((a, b) => a.distance - b.distance)
                [0].index;

            setCurrentIndex(closestIndex);
        };

        if (ref.current) {
            element = ref.current;
            element.addEventListener('scroll', scroll, {passive: true});
        }
        return () => {
            if (element) {
                element.removeEventListener('scroll', scroll);
            }
        }
    }, [ref]);

    return [
        currentIndex,
        useCallback((newIndex) => {
            setCurrentIndex(newIndex);
            const elementToScrollTo = getElementsForRef(ref)[newIndex];
            const baseCenterPosition = getBaseCenterPosition(ref);
            const centerPositionForElement = getCenterPositionForElement(elementToScrollTo);
            ref.current.scrollTo({
                left: centerPositionForElement - baseCenterPosition,
                behavior: 'smooth'
            });
        }, [ref, setCurrentIndex])
    ]
}
