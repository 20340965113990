// extracted by mini-css-extract-plugin
export var active = "Testimonial-module--active--13f67";
export var appearance_default = "Testimonial-module--appearance_default--8a8e9";
export var appearance_hero = "Testimonial-module--appearance_hero--eca3b";
export var appearance_secondHero = "Testimonial-module--appearance_secondHero--94834";
export var description = "Testimonial-module--description--1534b";
export var image = "Testimonial-module--image--cb15d";
export var intro = "Testimonial-module--intro--a86c9";
export var logo = "Testimonial-module--logo--e35f9";
export var name = "Testimonial-module--name--09db0";
export var person = "Testimonial-module--person--110c8";
export var root = "Testimonial-module--root--33850";
export var star = "Testimonial-module--star--3ca8b";
export var stars = "Testimonial-module--stars--bf001";