import React from 'react';
import * as styles from './HomeHero.module.scss';
import classNames from 'classnames';
import {Button} from "../Button";
import {GatsbyImage} from "gatsby-plugin-image";
import {LangLink} from "../LangLink";
import {useIntl} from 'gatsby-plugin-react-intl';
import {componentRef} from "../../functions/componentRef";
import {TypographyV2} from "../TypographyV2";

export function HomeHero({title, className, children, image, ...otherProps}) {
    const intl = useIntl();
    return (
        <section className={classNames(className, styles.root)} {...otherProps} {...componentRef('home-hero')}>
            <TypographyV2 tag="h1" variant="h1" className={styles.title}
                          {...componentRef.subcomponent('home-hero-title')}>
                {title}
            </TypographyV2>
            <TypographyV2 className={styles.content}>
                {children}
            </TypographyV2>
            <Button component={LangLink} to="/consultation"
                    className={styles.cta}
                    {...componentRef.subcomponent('go-digital')}>
                {intl.formatMessage({id: 'button.contactCTA'})}
            </Button>
            <div className={styles.imageWrapper}>
                <GatsbyImage image={image.gatsbyImageData} className={styles.image} alt="Hero"/>
            </div>
        </section>
    );
}
